import { useFormContext } from "react-hook-form";
import addMonths from "date-fns/addMonths";
import { useTranslation } from "@/i18n";
import { Text } from "@plum/ui-core";
import {
  Spacer,
  Grid,
  Box,
  HiddenVisually,
  Hidden,
  SliderProvider,
  Slide,
  Slider,
  SliderNavigationArrow,
} from "@plum/plum-ui-next";

import { DateFormats, formatDate } from "@/utils/dates";

import { DatesFilterFormState } from "../../types";

import { SFlexibleDatesMonthsOption } from "./Months.styles";

function generateYearMonthsArray() {
  const today = new Date();

  const months: Date[] = [];

  for (let month = 0; month < 12; month++) {
    months.push(addMonths(today, month));
  }

  return months;
}

const monthsArray = generateYearMonthsArray();

export function FlexibleDatesMonths() {
  const { watch } = useFormContext<DatesFilterFormState>();
  const { t } = useTranslation();

  const selectedMonths = watch("flexible.months");

  function isSelected(month: Date) {
    const formattedDate = formatDate(month, DateFormats.ISO_NO_DAY);

    if (!formattedDate) return false;

    return selectedMonths.includes(formattedDate);
  }

  function isDisabled(month: Date) {
    return !isSelected(month) && selectedMonths.length >= 4;
  }

  return (
    <div className="px-6">
      <Text as="h3" _scale="b-medium" _bold>
        {t("common:flexibleDates.months.title")}
      </Text>
      <Spacer size={16} />

      <Box
        css={{
          "@sm": {
            display: "none",
          },
        }}
      >
        <Grid columns={{ "@initial": 3 }} gap={{ "@initial": 8, "@xs": 16 }} placeholder="">
          {monthsArray.map((month, index) => (
            <MonthOption
              key={index}
              month={month}
              disabled={isDisabled(month)}
              selected={isSelected(month)}
              value={formatDate(month, DateFormats.ISO_NO_DAY)}
            />
          ))}
        </Grid>
      </Box>

      <Box
        css={{
          display: "none",
          "@sm": {
            display: "block",
          },
        }}
      >
        <SliderProvider
          options={{
            loop: false,
            spacing: 8,
            align: "center",
            containScroll: "trimSnaps",
          }}
        >
          <div className="flex items-center">
            <MonthSliderArrow direction="prev" />
            <Slider placeholder="">
              {monthsArray.map((month, index) => (
                <Slide key={`month-${index}`}>
                  <MonthOption
                    key={index}
                    month={month}
                    disabled={isDisabled(month)}
                    selected={isSelected(month)}
                    value={formatDate(month, DateFormats.ISO_NO_DAY)}
                  />
                </Slide>
              ))}
            </Slider>
            <MonthSliderArrow direction="next" />
          </div>
        </SliderProvider>
      </Box>

      <Hidden below="md" placeholder="">
        <Spacer size={8} />
        <Text _scale="b-tiny">{t("common:flexibleDates.months.hint")}</Text>
      </Hidden>
    </div>
  );
}

function MonthOption({ disabled, selected, month, value }) {
  const { register, setValue, watch } = useFormContext<DatesFilterFormState>();
  const { ref, name } = register("flexible.months"); // because component can be unmounted with persistent state, we cannot spread all the register methods (including onBlur)

  const formattedMonth = formatDate(month, DateFormats.MONTH_YEAR)?.split(" ");

  const handleChange = (e) => {
    const currentSelection = watch("flexible.months");

    setValue(
      "flexible.months",
      e.target.checked
        ? [...currentSelection, e.target.value]
        : currentSelection.filter((el) => el !== e.target.value)
    );
  };

  return (
    <label className={SFlexibleDatesMonthsOption({ disabled, selected })}>
      <input
        type="checkbox"
        value={value}
        disabled={disabled}
        ref={ref}
        name={name}
        onChange={handleChange}
        className="hidden"
      />
      <Text as="span" color="neutrals-9" _scale="b-small" _bold testId="flexible-months">
        {formattedMonth?.at(0)}
      </Text>
      <Text as="span" color="neutrals-9" _scale="b-tiny">
        {formattedMonth?.at(1)}
      </Text>
    </label>
  );
}

function MonthSliderArrow({ direction }: { direction: "prev" | "next" }) {
  return (
    <SliderNavigationArrow
      direction={direction}
      icon="chevron"
      iconSize="sm"
      css={{
        width: "$32",
        height: "$32",
        position: "absolute",
        zIndex: "$1",
        ...(direction === "prev" ? { left: "$32" } : { right: "$32" }),
        border: "1px solid $neutrals-4",
        borderRadius: "$rounded",
        backgroundColor: "$neutrals-0",
      }}
    />
  );
}
